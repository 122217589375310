import React from 'react';

import Rating from '../../Rating/Rating';

interface ICategoryItem {
  name: string;
  rating: number;
}

const CategoryItem: React.FC<ICategoryItem> = ({ name, rating, ...props }) => {
  if (!rating) return null;
  return (
    <div className="flex flex-row justify-between mb-1" {...props}>
      <p className="text-sm font-normal text-gray-900 pt-0.5 semiHighlight">{name}</p>
      <Rating number={rating} iconSize={14} className="pb-2 text-sm font-bold text-gray-800" />
    </div>
  );
};

export default CategoryItem;
