import React from 'react';

import css from './Tag.module.css';

export enum TagColors {
  primary = 'primary',
  gray = 'gray',
  yellow = 'yellow',
}

export enum TagWeight {
  normal = 'normal',
  bold = 'bold',
}

export enum TagSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

type ITagElement = React.HTMLAttributes<HTMLSpanElement> | React.HTMLAttributes<HTMLButtonElement>;

interface IProps {
  color?: TagColors;
  isPinned?: boolean;
  weight?: TagWeight;
  size?: TagSizes;
  onClick?: () => void;
}

const Tag: React.FC<IProps & ITagElement> = ({
  children,
  className,
  color = TagColors.primary,
  isPinned = false,
  weight = TagWeight.normal,
  size = TagSizes.small,
  onClick,
  ...props
}) => {
  const TagTag = onClick ? 'button' : 'span';

  return (
    <TagTag
      data-testid="tag"
      className={`${css.tag} inline-flex leading-none items-center highlight rounded-full uppercase transition-all duration-500 ${className}`}
      data-color={color}
      data-weight={weight}
      data-size={size}
      data-clickable={!!onClick}
      {...(isPinned && {
        ['data-name']: 'Pinned listing', // for test purposes, no translation needed
        ['data-pinned']: true,
      })}
      onClick={onClick}
      {...props}>
      <span className="items-center inline-grid autoType100 gap-1.5 grid-flow-col">{children}</span>
    </TagTag>
  );
};

export default Tag;
