import { TRootState } from '@/redux/rootReducer';
import { IImage } from '@/services/types/search/rentals/id';
import { getFormattedImages } from '@/utility/rentals';
import { formatReviewData } from '@/utility/reviews';

export const ownerInfoSelector = (state: TRootState) => state.owner.owner.data;
export const ownerIdSelector = (state: TRootState) => state.owner.owner.id;
export const ownerRentalsSelector = (state: TRootState) => state.owner.rentals.data;
export const ownerRentalsLoading = (state: TRootState) => state.owner.rentals.isFetching;
export const ownerRentalImagesSelector = (state: TRootState) => {
  if (state.owner.rentals.data) {
    return state.owner.rentals.data.reduce((allImages: IImage[], rental) => {
      const rentalImages = rental?.images ? getFormattedImages(rental) : [];
      return [...allImages, ...rentalImages];
    }, []);
  }
  return [];
};
export const ownerRentalMetaSelector = (state: TRootState) => state.owner.rentals.meta;
export const ownerStaysSelector = (state: TRootState) => state.owner.stays.data;
export const ownerStaysLoading = (state: TRootState) => state.owner.stays.isFetching;
export const ownerStayImagesSelector = (state: TRootState) => {
  if (state.owner.stays.data) {
    return state.owner.stays.data.reduce((allImages: IImage[], stay) => {
      const stayImages = stay?.images ? getFormattedImages(stay) : [];
      return [...allImages, ...stayImages];
    }, []);
  }
  return [];
};
export const ownerStaysMetaSelector = (state: TRootState) => state.owner.stays.meta;
export const ownerReviewsSelector = (state: TRootState) =>
  state.owner.reviews.data.map(review => formatReviewData(review, state.queryParams));

export const ownerAverageRating = (state: TRootState) => {
  if (state.owner.rentals.data) {
    const listingsWithRating = state.owner.rentals.data.filter(
      listing => !!listing.average_ratings,
    );
    // @ts-expect-error fixable: unchecked index access
    return listingsWithRating.length > 0 ? listingsWithRating[0].average_ratings : undefined;
  }
  return undefined;
};
