import React from 'react';
import { useIntl } from 'react-intl';

import { STAR } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import { roundRatingNumber } from '@/utility/reviews';
interface IProps {
  number: number;
  className?: string;
  iconClassName?: string;
  iconSize?: number;
}

const Rating: React.FC<IProps> = ({
  className = '',
  iconClassName,
  number,
  iconSize = 12,
  ...props
}) => {
  const intl = useIntl();
  // enforce single decimal digit and hide decimal if a whole number
  const fixedRating = roundRatingNumber(number);
  const ratingAriaLabel = intl.formatMessage(
    { defaultMessage: '{rating} out of 5 stars', id: 'Wp2bK6' },
    { rating: fixedRating },
  );
  return (
    <span
      aria-label={ratingAriaLabel}
      {...props}
      className={`inline-grid grid-flow-col gap-0.5 items-center text-green-500 ${className}`}>
      <Icon className={iconClassName} name={STAR} width={iconSize} height={iconSize} />
      <span className="text-gray-900">{fixedRating}</span>
    </span>
  );
};

export default Rating;
