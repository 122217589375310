import React from 'react';
import { useIntl } from 'react-intl';

import { IAverageRatings, IAverageReviews } from '@/services/types/search/rentals/id';
import { roundRatingNumber } from '@/utility/reviews';

import { IReview } from '../../AllReviews/AllReviews';
import CategoryItem from './CategoryItem';
import RatingBar from './RatingBar';

interface ICategoryReviewsRating {
  averageRatings: IAverageReviews | undefined;
  ownerAverageRating: IAverageRatings | undefined;
  isStay?: boolean;
  isCampground?: boolean;
  reviews: IReview[];
}

const CategoryReviewsRating: React.FC<ICategoryReviewsRating> = ({
  averageRatings,
  ownerAverageRating,
  isStay,
  isCampground,
  reviews,
}) => {
  const intl = useIntl();
  // If both arrays are missing, just don't render the component
  if ((!averageRatings || !averageRatings.rental?.length) && !ownerAverageRating) {
    return null;
  }

  const rentalRatings =
    !!averageRatings && !!averageRatings.rental?.length
      ? averageRatings.rental.filter(r => {
          return (!isStay && !isCampground) || r.key !== 'mechanicalScore';
        })
      : [];

  const calculateRatingDistribution = () => {
    const ratingCounts = [0, 0, 0, 0, 0];

    const filteredReviews = reviews.filter(review => review.ratingNumber > 0);

    filteredReviews.forEach(item => {
      // Round for ratings like 1.5
      // @ts-expect-error need to fix index checking
      ratingCounts[Math.round(item.ratingNumber) - 1]++;
    });

    const totalCount = filteredReviews.length;
    const ratingPercentages = ratingCounts.map(count => Math.round((count / totalCount) * 100));

    // If the sum is not quite 100 add the diff to the highest rating
    const sum = ratingPercentages.reduce((acc, curr) => acc + curr, 0);
    if (sum !== 100) {
      const diff = 100 - sum;
      const maxIndex = ratingPercentages.indexOf(Math.max(...ratingPercentages));
      // @ts-expect-error need to fix index checking
      ratingPercentages[maxIndex] += diff;
    }

    const result = ratingPercentages
      .map((percentage, index) => {
        return {
          index: index + 1,
          percentage: roundRatingNumber(percentage),
        };
      })
      .reverse();
    return result;
  };
  return (
    <>
      <div className="mb-8 md:hidden">
        {reviews &&
          calculateRatingDistribution().map(item => (
            <RatingBar key={item.index} rating={item.index} percentage={item.percentage} />
          ))}
      </div>
      <div className="md:w-1/2">
        {!!ownerAverageRating && !isCampground && (
          <CategoryItem
            key="communication"
            name={intl.formatMessage({ defaultMessage: 'Communication', id: 'n5D/Ku' })}
            rating={roundRatingNumber(ownerAverageRating.communication_score)}
            data-testid="communicationScore"
          />
        )}
        {rentalRatings.map(category => (
          <CategoryItem
            key={category.key}
            name={category.name}
            rating={roundRatingNumber(category.score)}
            data-testid={category.key}
          />
        ))}
      </div>
    </>
  );
};

export default CategoryReviewsRating;
