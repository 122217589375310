import isFunction from 'lodash/isFunction';
import React from 'react';

import css from './FormattedPlaintext.module.css';

type TParagraphSpecificFnOrValue =
  | boolean
  | string
  | ((text: string, index: number) => boolean | string);

export interface IFnOrValueAttrs {
  [index: string]: TParagraphSpecificFnOrValue;
}

interface IProps {
  text: string;
  paragraphClasses?: TParagraphSpecificFnOrValue;
  paragraphDataAttrs?: IFnOrValueAttrs;
  paragraphAriaAttrs?: IFnOrValueAttrs;
}

const formatIntoParagraphs = (text: string) => {
  return text ? text.split(/\s*\n\s*\n\s*/).map(paragraphs => paragraphs.split(/\s*\n\s*/)) : [];
};

const extractAttrs = (
  attributePrefix: string,
  data: IFnOrValueAttrs | undefined,
  text: string,
  index: number,
) => {
  return data
    ? Object.keys(data).reduce<{ [index: string]: boolean | string }>((attrs, attr) => {
        const fnOrValue = data[attr];
        // @ts-expect-error fixable: unchecked index access
        attrs[`${attributePrefix}-${attr}`] = isFunction(fnOrValue)
          ? fnOrValue(text, index)
          : fnOrValue;

        return attrs;
      }, {})
    : {};
};

const PlaintextToMarkup: React.FC<IProps> = ({
  paragraphClasses,
  text,
  paragraphDataAttrs,
  paragraphAriaAttrs,
}) => {
  const paragraphs = formatIntoParagraphs(text).map(
    (paragraph: string[], paragraphIndex: number) => {
      const concattedParagraph = paragraph.join('');
      const paragraphClass = isFunction(paragraphClasses)
        ? paragraphClasses(concattedParagraph, paragraphIndex)
        : paragraphClasses;

      const dataAttrs = extractAttrs(
        'data',
        paragraphDataAttrs,
        concattedParagraph,
        paragraphIndex,
      );
      const ariaAttrs = extractAttrs(
        'aria',
        paragraphAriaAttrs,
        concattedParagraph,
        paragraphIndex,
      );

      const attrs = {
        className: (typeof paragraphClass === 'string' ? paragraphClass : '') + ` ${css.paragraph}`,
        ...dataAttrs,
        ...ariaAttrs,
      };

      return (
        <span key={concattedParagraph || paragraphIndex} {...attrs}>
          {paragraph.map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
              {line}
              {lineIndex < paragraph.length - 1 && <br />}
            </React.Fragment>
          ))}
        </span>
      );
    },
  );

  return <>{paragraphs}</>;
};

export default PlaintextToMarkup;
