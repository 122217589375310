export const getAbbreviatedSurname = (name: string) => {
  const hasAtLeast2Words = /[a-zA-Z]+\s+[a-zA-Z]+/g;
  if (hasAtLeast2Words.test(name)) {
    const [fistName, surName] = name.split(' ');
    // @ts-expect-error fixable: unchecked index access
    const FormattedName = `${fistName} ${surName[0]}.`;
    return FormattedName;
  }
  return name;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const numbers = phoneNumber.replace(/\D/g, '');
  const match = numbers.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return phoneNumber;
};
