/**
 * Assigning multiple refs to a component.
 * reference: https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
 *
 * @param refs list of refs
 */

export const mergeRefs = (
  ...refs: Array<
    | ((instance: HTMLParagraphElement | null) => void)
    | React.MutableRefObject<HTMLParagraphElement | null>
    | null
  >
) => {
  const filteredRefs = refs.filter(Boolean);

  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];

  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        (ref as (param: any) => void)(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};
