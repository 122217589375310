import React from 'react';

import ResponsiveImage, { ImageSizes } from '@/components/utility/ResponsiveImage/ResponsiveImage';

import css from './Avatar.module.css';

type Size = '40' | '128' | '160';

const mapSizeToResponsiveImage: {
  [key in Size]: {
    sizes: string;
    sourceSizes: ImageSizes;
  };
} = {
  ['40']: {
    sizes: '40px',
    sourceSizes: ['square40', 'square80'],
  },
  ['128']: {
    sizes: '(max-width: 768px) 96px, 128px',
    sourceSizes: ['square96', 'square192', 'square128', 'square256'],
  },
  ['160']: {
    sizes: '(max-width: 768px) 96px, 160px',
    sourceSizes: ['square96', 'square192', 'square160', 'square320'],
  },
};

type IAvatarElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  name?: string;
  photoUrl?: string;
  size?: Size;
  className?: string;
}

const Avatar: React.FC<IProps & IAvatarElement> = ({
  name,
  photoUrl,
  size = '40',
  className = '',
}) => {
  if (!name && !photoUrl) return null;

  return (
    <div
      aria-hidden
      className={`autoType300 border border-gray-200 bg-gray-200 inline-flex items-center justify-center overflow-hidden text-gray-500 rounded-full w-full ${css.container} ${className}`}
      data-testid="user-picture">
      {photoUrl && (
        <ResponsiveImage
          src={photoUrl}
          className="object-cover w-full"
          role="presentation"
          alt=""
          {...mapSizeToResponsiveImage[size]}
        />
      )}
      {!photoUrl && name && (
        <svg
          viewBox="0 0 100 100"
          xmlns="https://www.w3.org/2000/svg"
          className="flex items-center justify-center fill-current"
          fontSize="200%"
          height="100%"
          width="100%">
          <text style={{ dominantBaseline: 'middle' }} x="50%" y="50%" textAnchor="middle">
            {name && name[0]}
          </text>
        </svg>
      )}
    </div>
  );
};

export default Avatar;
