import { LinearProgress } from '@material-ui/core';
import React from 'react';

import Rating from '../../Rating/Rating';

interface IRatingBarProps {
  rating: number;
  percentage: number;
}

const RatingBar: React.FC<IRatingBarProps> = ({ rating, percentage }) => {
  return (
    <div
      className="grid gap-x-4 grid-flow-col grid-cols-12 place-items-center justify-items-start"
      data-testid={`${rating}-star-review`}>
      <Rating
        number={rating}
        iconSize={14}
        className="text-sm font-bold text-left text-gray-800 col-span-2"
      />
      <div className="w-full col-span-8">
        <div className="[&>div]:bg-[#dddddd] [&>div>div]:bg-primary-500">
          <LinearProgress value={percentage} variant="determinate" />
        </div>
      </div>
      <div className="text-base col-span-2 text-primary-base text">
        <div className="text-base text-primary-base semiHighlight">{`${percentage} %`}</div>
      </div>
    </div>
  );
};

export default RatingBar;
